<template>
  <div>
    <bread-crumb :items="items"></bread-crumb>
    <v-container>
      <p>
        <b>Christore Books</b> is Ekiti Favourite and award winning bookstore
        with variety of different genres available. From international and local
        authors, motivational best sellers to fiction and non-fictions, books
        are available at our different locations across the state.
      </p>
      <p>
        <b> Gift items</b> are also available, perfumes, cards, toys and many
        more. Customized items can be requested for special occasions such as
        birthdays, anniversaries, Christmas, valentine and on specific holidays.
      </p>
      <p>
        <b>Bibles and Religious Items </b> are available inside the bookstore.
        As a Christian bookstore, items are currently in display for customers
        such as bibles, bells, communion wine and many more.
      </p>

      <h2 class="text-center mt-7">To Make More Enquries</h2>
      <v-row justify="center">
        <v-col cols="12" md="7" lg="8">
          <contact-form category="bookstore" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import ContactForm from "@/components/ContactForm.vue";
export default {
  name: "BookOrder",
  components: { BreadCrumb, ContactForm },

  data: () => ({
    items: [
      {
        text: "Home",
        disabled: false,
        link: "Home",
      },
      {
        text: "Bookstore",
        disabled: false,
        link: "Bookstore",
      },
      {
        text: "Shop",
        disabled: true,
      },
    ],
  }),
};
</script>
